import * as React from "react";
import styled from "styled-components";

import dayjs from "dayjs";
import Map from "react-map-gl";

import { breakpoints } from "../styles/variables";
import {
  StyledLeaderboard,
  StyledLeaderboardLeft,
  StyledLeaderboardRight,
  StyledLeaderboardText,
  StyledLeaderboardTextText,
  StyledLeaderboardTextTitle,
} from "./Learderboard";
import MapMarker from "./MapMarker";

const StyledLocation = styled.div`
  text-decoration: none;
  background-color: #e4e4e4;
  display: flex;
  font-size: 16px;
  flex-direction: column;
  border-bottom: 1px solid #a3a3a3;
  @media (min-width: ${breakpoints.md}px) {
    flex-direction: row;
    height: 402px;
    transition: all 0.2s;
  }
`;

const LocationLeft = styled.div`
  img {
    max-width: 100%;
  }
  @media (min-width: ${breakpoints.md}px) {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 20px 40px 175px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
`;
const LocationRight = styled.div`
  padding: 20px;
  @media (min-width: ${breakpoints.md}px) {
    width: 50%;
    padding: 40px 40px 40px 20px;
  }
`;

const StyledLocationsWithMap = styled.div`
  @media (min-width: ${breakpoints.md}px) {
  }
`;

const LocationTitle = styled.div`
  font-size: 30px;
  font-weight: bold;
  width: 100%;
`;

const LocationSubtitle = styled.div`
  margin-bottom: 25px;
`;

const LocationOpeningHours = styled.div`
  font-size: 14px;
`;

const LocationOpeningHoursTitle = styled.h4`
  font-size: 14px;
  margin-bottom: 8px;
`;

interface LocationsWithMapType {
  locations: any;
}

const LocationsWithMap: React.FC<LocationsWithMapType> = ({ locations }) => (
  <StyledLocationsWithMap>
    <StyledLeaderboard>
      <StyledLeaderboardLeft>
        <StyledLeaderboardText>
          <StyledLeaderboardTextTitle>Standorte</StyledLeaderboardTextTitle>
          <StyledLeaderboardTextText>
            RENTIR Fahrzeugvermietung, die Nummer 1 der Deutschschweiz.
          </StyledLeaderboardTextText>
        </StyledLeaderboardText>
      </StyledLeaderboardLeft>
      <StyledLeaderboardRight>
        <Map
          initialViewState={{
            longitude: 9.2840566,
            latitude: 47.1940929,
            zoom: 7.5,
          }}
          mapStyle="mapbox://styles/mapbox/light-v11"
          mapboxAccessToken="pk.eyJ1IjoiZ29lYXN0IiwiYSI6ImNsZ20xNmpidTAwYmEzdHM5aDluMTRnaDgifQ.UPy7WH4iLpxsG90BNxfJgQ"
          scrollZoom
          style={{ width: "100%", height: "100%" }}
        >
          {locations.data.map((location: any, index: any) => {
            if (location.attributes.latitude) {
              return (
                <MapMarker
                  key={location.id}
                  locationId={location.id}
                  longitude={location.attributes.longitude}
                  latitude={location.attributes.latitude}
                  title={location.attributes.name}
                />
              );
            }
          })}
        </Map>
      </StyledLeaderboardRight>
    </StyledLeaderboard>
    {locations.data.map((location: any, index: any) => {
      let counter = 0;

      let holidays: any = [];

      const orderedHolidays = location.attributes.holidayHours.sort(
        (a: any, b: any) => dayjs(a.date).valueOf() - dayjs(b.date).valueOf()
      );

      orderedHolidays.map((holiday: any) => {
        if (dayjs(holiday.dateTo).isAfter(dayjs())) {
          holidays.push(holiday);
        }
      });

      return (
        <StyledLocation key={index} id={`location${location.id}`}>
          <LocationLeft>
            {location.attributes.images.length && (
              <img src={location.attributes.images[0].fullsize} alt="" />
            )}
          </LocationLeft>
          <LocationRight>
            <LocationTitle>{location.attributes.name}</LocationTitle>
            <LocationSubtitle>
              {location.attributes.addressCompact}
            </LocationSubtitle>
            {location.attributes.description}
            <br />
            <LocationOpeningHoursTitle>
              Öffnungszeiten
            </LocationOpeningHoursTitle>
            <LocationOpeningHours
              dangerouslySetInnerHTML={{
                __html: location.attributes.openingHoursCompact,
              }}
            />
            <LocationOpeningHoursTitle>
              Ferien und Feiertage
            </LocationOpeningHoursTitle>
            <LocationOpeningHours>
              {holidays.map((holiday: any) => {
                counter += 1;
                if (counter < 6) {
                  return (
                    <div>
                      {`${dayjs(holiday.date).format("DD.MM.YYYY")}`}
                      {holiday.dateTo && (
                        <>
                          {" - "}
                          {`${dayjs(holiday.dateTo).format("DD.MM.YYYY")}`}
                        </>
                      )}{" "}
                      <strong>{holiday.title}</strong>
                      {holiday.open === true && (
                        <>
                          <strong> / Spezielle Öffnungszeiten: </strong>
                          {holiday.morningStart && (
                            <span>{holiday.morningStart}</span>
                          )}
                          {holiday.morningEnd && (
                            <span> - {holiday.morningEnd}</span>
                          )}
                          {holiday.afternoonStart && (
                            <span>, {holiday.afternoonStart}</span>
                          )}
                          {holiday.afternoonEnd && (
                            <span> - {holiday.afternoonEnd}</span>
                          )}
                        </>
                      )}
                    </div>
                  );
                }
                return null;
              })}
            </LocationOpeningHours>
          </LocationRight>
        </StyledLocation>
      );
    })}
  </StyledLocationsWithMap>
);

export default LocationsWithMap;
